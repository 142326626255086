<template>
  <div>
    <section
      v-for="contentElement in content.nestedContent"
      :key="contentElement.key"
      :class="[getSpacingVertical(contentElement),
               getElementTemplate(contentElement) ? getSpacingTopClass(contentElement) + ' ' + getSpacingBottomClass(contentElement) : null ]"
    >
      <component
        :is="getElementTemplate(contentElement)"
        v-if="getElementTemplate(contentElement)"
        :content="contentElement"
      />
    </section>
  </div>
</template>

<script>
import elementResolverMixin from '@/mixins/elementResolverMixin';

import {
  getSpacingTopClass,
  getSpacingBottomClass
} from '@/utils/spacing-classes.js';

export default {
  name: 'FourOFour',
  components: {
  },
  mixins: [elementResolverMixin],
  methods: {
    getSpacingTopClass: getSpacingTopClass,
    getSpacingBottomClass: getSpacingBottomClass
  }
};
</script>
